<template>
  <div>
    <section class="main_sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="main_content">
              <h2>
                Join &amp; Get Access To Our $8,000,000 B&amp;R Infrastructure
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="banner_sec">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <div class="banner_content">
              <div class="d-flex justify-content-center align-items-center">
                <img
                  src="../assets/images/bannerimages/logo1.png"
                  alt=""
                  class="banner_logoimg"
                />
              </div>
              <h1>
                The Only Growth Creator Community You Need To Scale Past
                $100K/month Leveraging The New Build &amp; Release Model
              </h1>

              <h2>
                Join The Top <strong>1%</strong> Of Growth Creators And Industry
                Experts Implementing The Build &amp; Release Model To
                Effortlessly Scale Past $100K/month
              </h2>

              <a
                href="https://www.fanbasis.com/agency-checkout/Clientacquisition.io/kRGY"
                target="_blank"
                ><h4>Join NBL For Just $1 In Your First Week</h4>
                <p>And Access The 7-Figure Sales Agency Walkthrough</p></a
              >

              <h6>
                Try week one for $1, then $49/week - Cancel anytime. No
                Commitment!
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="commoditi_sec">
      <div class="container">
        <div class="row text-center">
          <div class="col-12">
            <div class="commoditi_content">
              <h1>
                Go From A Commoditized & Overworked Online Service Provider To
                Clearing $100,000/m Profit As A Growth Creator By Implementing
                The Build & Release Model.
              </h1>

              <p>
                If you don’t know who I am, my name is Serge Gatari.<br /><br />I
                run <strong>Clientacquisition.io</strong>, a growth creation
                firm that I started in 2021 after realizing that the traditional
                model of running agencies and typical coaching/consulting
                businesses was the fastest path to remaining average and making
                just enough to survive due to the low barrier to entry.<br /><br />I
                went on to study huge organizations like McKinsey &amp; Company,
                which generate over $1 billion in monthly revenue, and realized
                that they had figured out a way to get paid a lot of money
                upfront but also retain the same clients who had paid them a lot
                of money forever—through technology.<br /><br />In the last 24
                months, I’ve gone from shutting down a $500k/year
                appointment-setting agency to cash collecting over
                <span class="text-span-128">$8,000,000</span> by applying the
                same model.<br /><br />Making more money from your business
                isn’t about working harder, it comes from optimizing all your
                decisions and actions to create the most leverage in your offer,
                your acquisition, and your fulfillment. <br /><br />Look around
                you, and you’ll realize that everyone at the top isn’t playing
                the same game you are, they’ve all figured out a way to jump
                into a more leveraged vehicle.<br /><br />The new wealth
                creation roadmap with an online business is really simple:
                become a creator, build and release turnkey growth solutions
                ($10k-$100k+), and partner with the best clients in exchange for
                a revenue share or equity.<br /><br />I created the
                <span class="text-span-129">Natural Born Leader</span> community
                to give service providers and experts, who have realized that
                the game has changed, an opportunity to learn how they can
                finally start thriving instead of just surviving.<br /><br />If
                you’re stuck below $100,000/month profit, join the community to
                access our $8,000,000 growth infrastructure, our Build &amp;
                Release Model, and proven turnkey solutions you can start
                selling for $15,000+. <br /><br />You can also apply for a
                one-on-one session with me to build your unique mechanism and a
                personalized scaling roadmap.<br />
              </p>

              <a href="" target="_blank"
                ><h4>Join NBL For Just $1 In Your First Week</h4>
                <p>And Access The 7-Figure Sales Agency Walkthrough</p></a
              >

              <h1 class="secondheading">
                The Traditional Model Of Scaling Service Based Businesses Is
                Unprofitable...
              </h1>

              <div class="tradition_img">
                <img src="../assets/images/bannerimages/home1.png" />
                <img src="../assets/images/bannerimages/home2.png" />
                <img
                  src="../assets/images/bannerimages/home3.png"
                  class="home3"
                />
              </div>

              <p>
                In 2021 I shut down my $30K/month appointment setting agency
                simply because I realized it would’ve been painfully hard to
                grow that business past $100,000 per month, without experiencing
                higher overhead costs, and clients experiencing lower
                results.<br /><br />I took a commoditized offer, and implemented
                growth systems, protocols, and installed higher leveraged
                delivery mechanisms.<br /><br />I turned the same $1,500 offer
                into a <span class="text-span-87">$15,000</span> growth
                infrastructure… <br /><br />12 months later, I was generating
                <span class="text-span-88">$500,000/month</span> with less than
                $3,000/month in marketing spend. <br /><br />All while working
                with fewer clients, and bringing 10x more profits. <br />
              </p>
              <div class="reportoverview">
                <img
                  src="../assets/images/bannerimages/home4.jpg"
                  class="home4"
                />
              </div>

              <p>
                Instead of working harder, I decided to model companies that
                were effortlessly scaling &amp; came up with the Build &amp;
                Release Model. <br /><br />In the last 6 months, we’ve
                researched, developed &amp; implemented this Growth protocol
                &amp; infrastructure in over 50 niches with our partners.<br /><br />Results?<br />‍<br />✔
                <span class="text-span-112"
                  >More upfront capital collected</span
                >
                than standard retainer-based agency models.<br />‍<br />✔
                <span class="text-span-113">Higher enterprise value</span> from
                building a predictable cashflow business.<br />‍<br />✔
                <span class="text-span-114"
                  >Highly impactful client results</span
                >
                from being able to bridge their gap through solving the whole
                problem, and not just their bottlenecks. <br />
              </p>
              <div class="reportoverview">
                <img
                  src="../assets/images/bannerimages/home5.png"
                  class="home5"
                />
              </div>

              <h1 class="subheadline">Inside The NBL Community</h1>
              <h1>By Joining The NBL Community, You'll Learn</h1>

              <div class="nblcommunity">
                <img src="../assets/images/bannerimages/nbl1.webp" alt="" />
                <img src="../assets/images/bannerimages/nbl2.webp" alt="" />

                <img src="../assets/images/bannerimages/nbl3.webp" alt="" />

                <img src="../assets/images/bannerimages/nbl4.webp" alt="" />
              </div>
              <a href="" target="_blank"
                ><h4>Join NBL For Just $1 In Your First Week</h4>
                <p>And Access The 7-Figure Sales Agency Walkthrough</p></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="whonbl_sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="whonbl_content">
              <h1>
                Get Access To Winning Build &amp; Release Infrastructures,
                Offers, And Insights In
                <span class="text-span-122">50+ Niches</span>
              </h1>
              <h1 class="subheadline">
                Inside The NBL Community, You'll Get Access To Insights And
                Build &amp; Release Offers We Build For Our Growth Creator
                Partners That You Can Implement In Your Business To Go From A
                Commoditized Offer Into <strong>$15,000</strong> Growth
                Infrastructure.
              </h1>

              <div>
                <div class="nbl_perks">
                  <div>
                    <h1>
                      $50,000/mo Growth Infrastructure For Local Businesses
                      <span class="text-span-117">(Valued at $15,000)</span>
                    </h1>
                    <p>
                      Access one of the most in-demand infrastructures to sell
                      in 2024 And The Exact B&amp;R Offer I Designed For One Of
                      Our Members LIVE.
                    </p>
                  </div>
                  <div>
                    <img
                      src="../assets/images/bannerimages/perk1.png"
                      alt=""
                      class="perkimg"
                    />
                  </div>
                </div>
                <div class="nbl_perks">
                  <div>
                    <h1>
                      YouTube & Content Growth Infrastructure
                      <span class="text-span-117"> (Valued at $5,000)</span>
                    </h1>
                    <p>
                      Get Instant Access To The $30,000 YouTube Infrastructure
                      And Why is this one of the most important infrastructure
                      to own as a business owner.
                    </p>
                  </div>
                  <div>
                    <img
                      src="../assets/images/bannerimages/perk2.png"
                      alt=""
                      class="perkimg"
                    />
                  </div>
                </div>
                <div class="nbl_perks">
                  <div>
                    <h1>
                      $10,000 Outbound Growth Infrastructures.
                      <span class="text-span-117">(Valued at $15,000)</span>
                    </h1>
                    <p>
                      Get Instant Access To The $10,000 Outbound Growth
                      Infrastructure That Is Responsible For Generating Our
                      Partners $50K/month And More.
                    </p>
                  </div>
                  <div>
                    <img
                      src="../assets/images/bannerimages/perk3.png"
                      alt=""
                      class="perkimg"
                    />
                  </div>
                </div>
                <div class="nbl_perks">
                  <div>
                    <h1>
                      Weekly Support Calls + Growth Creator Challenge Recordings
                    </h1>
                    <p>
                      Apply to have a 1-on-1 Offer Design Session with Serge or
                      join the call as he designs new Growth Infrastructures
                      Weekly + Access The 4-Week Growth Creator Challenge Call
                      Recordings.
                    </p>
                  </div>
                  <div>
                    <img
                      src="../assets/images/bannerimages/perk4.png"
                      alt=""
                      class="perkimg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="feature_sec">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="row justify-content-between feature_row">
              <div class="col-lg-5 col-md-12">
                <div class="feature_content lg-mb-0 mb-5">
                  <img
                    src="../assets/images/bannerimages/logo1.png"
                    width="150px"
                  />
                  <h1 class="">
                    Learn Our Scaling Playbook We Implement For Our Partners In
                    50+ Niches
                  </h1>
                  <h1 class="subheadline">
                    Get Access To The Foolproof Growth Creator Model We Use To
                    Scale Our Partners To
                    <span class="text-span-94">$100K-$500K/month</span> In
                    <span class="text-span-93">50 Different Niches.</span>
                  </h1>

                  <a
                    href="https://www.fanbasis.com/agency-checkout/Clientacquisition.io/kRGY"
                    target="_blank"
                    ><h4>Yes! I Want To Join NBL Community</h4>
                    <p>And Access The 7-Figure Sales Agency Walkthrough</p></a
                  >
                </div>
              </div>
              <div class="col-lg-5 col-md-12">
                <div class="feature_data">
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Learn How We’re Leveraging A.I To Acquire Strategic
                        &amp; Tactical Insights to build our offers.
                      </p>
                    </div>
                  </div>
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Access our step by step playbook around building Unique
                        Niche Specific Growth Infrastructure.
                      </p>
                    </div>
                  </div>
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Access Our Value-Based Pricing allowing us to charge
                        $10,000-$100,000+ upfront for implementing Growth
                        Systems.
                      </p>
                    </div>
                  </div>
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Learn How We Dominate Competitive Niches by leveraging
                        Process Maps & Process Selling Video Sales Letters At
                        Scale To Nurture & Sell.
                      </p>
                    </div>
                  </div>
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Access our YouTube Creator MOAT Insight We Implement For
                        Our Partners To Build 7 figure Businesses with 1k-2k
                        subscribers.
                      </p>
                    </div>
                  </div>
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Access The Paid Ads Playbook We leverage to generate
                        thousands of leads & hundreds of appointments for our
                        partners in 50+ niches.
                      </p>
                    </div>
                  </div>
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Access our Fulfillment Infrastructure Breakdown allowing
                        us to fulfill $100k-$500k/m in new monthly revenue
                        effortlessly.
                      </p>
                    </div>
                  </div>
                  <div
                    class="feature_item"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <div class="leftborder"></div>
                    <div class="d-flex">
                      <div class="checkbox input"></div>
                      <p>
                        Access Our Data Collection Systems & How We use Data As
                        A Retention Mechanism.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="excess_sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="excess_content">
              <h1 class="heading-37 opt-in s2-copy nbl-headline">
                Get Early Access To LIVE Calls Implementing Our
                <span class="text-span-130">$8,000,000</span> Build &amp;
                Release Thesis In 50+ Niches
              </h1>

              <div>
                <div class="excess_item">
                  <div class="excess_first">
                    <div class="d-flex align-items-center gap-1">
                      <div class="glowing-circle"></div>
                      <h1>AI Content Infrastructure Breakdown</h1>
                    </div>
                    <div class="glowing-text">NEXT</div>
                  </div>
                  <div class="excess_second">
                    Learn how we leverage AI to create infinite video content
                    for organic and paid traffic.
                  </div>
                </div>
                <div class="excess_item excess_item2">
                  <div class="excess_first">
                    <div class="d-flex align-items-center gap-1">
                      <div class="glowing-circle"></div>
                      <h1>AI Content Infrastructure</h1>
                    </div>
                    <div class="glowing-text">SOON</div>
                  </div>
                  <div class="excess_second">
                    Learn how we leverage AI to create infinite video content
                  </div>
                </div>
                <div class="excess_item excess_item3">
                  <div class="excess_first">
                    <div class="d-flex align-items-center gap-1">
                      <div class="glowing-circle"></div>
                      <h1>AI Content Infrastructure Breakdown</h1>
                    </div>
                    <div class="glowing-text">SOON</div>
                  </div>
                  <div class="excess_second">
                    Learn how we leverage AI to create infinite video content
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="case_sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="case_content">
              <div class="case_badges">
                ClientAcquisition.io Partners' Success Stories
              </div>
              <h1>
                See What Our Partners Have Experienced After Switching To
                Selling Build &amp; Release Offers
              </h1>

              <div class="proofcopy_data">
                <div class="proof_item">
                  <div>
                    <h3>Wyatt | Founder of TacticalConsulting.io</h3>
                    <p>
                      Smartest 16-year-old kid who went down the rabbit hole of
                      AI &amp; got early in Air.ai in their licensing deal.
                      Started making $5k-10k/m selling his time integrating the
                      dialing agents for $500-$1k at a time. <br /><br />When he
                      became a partner in December of 2023, we turned his offer
                      into a B&amp;R offer and priced it at $12k, implemented
                      our acquisition infrastructure &amp; fully removed him
                      from the acquisition process.<br /><br />The first 9 days
                      of February, he hit
                      <span class="text-span-79">$33,000 cash collected</span>
                      pacing for his first six figure month at 16 years old.
                    </p>
                  </div>

                  <div class="proof_img">
                    <img
                      src="../assets/images/bannerimages/proof1.png"
                      alt=""
                      class="image-98"
                    />
                  </div>

                  <div class="proof_badges">
                    Building &amp; Releasing AI Dialling Infrastructures
                  </div>
                </div>

                <div class="proof_item">
                  <div>
                    <h3>Steve | Founder of BuildGrowth</h3>
                    <p>
                      Before he became a partner, he was selling a coaching
                      program for $4-5k to wood entrepreneurs who wanted to
                      scale their business and was stuck around 20k/m. We
                      changed his positioning from coaching to building &
                      releasing a protocol & systems into their businesses,
                      implemented our acquisition infrastructure and 60 days
                      later, Generated his first
                      <span class="text-span-79">$50k+ Month</span>.
                      <br /><br />
                      Today, he’s selling B&R & Partnerships offers to 20M/year
                      manufacturing companies who need new acquisition
                      infrastructures.
                    </p>
                  </div>

                  <div class="proof_img proofgrid">
                    <div class="col-span-2">
                      <img
                        src="../assets/images/bannerimages/proof2.png"
                        alt=""
                        class="image-98"
                      />
                    </div>
                    <div>
                      <img
                        src="../assets/images/bannerimages/proof3.png"
                        alt=""
                        class="image-98"
                      />
                      <img
                        src="../assets/images/bannerimages/proof4.png"
                        alt=""
                        class="image-98"
                      />
                    </div>
                  </div>

                  <div class="proof_badges">
                    Wood & manufacturing businesses.
                  </div>
                </div>

                <div class="proof_item">
                  <div>
                    <h3>Oliver | Founder of Growthsolutions.io</h3>
                    <p>
                      Started working with us after being stuck at 30k/m while
                      working with 60 agencies per month. Knowing how Insanely
                      competitive the market was we re-built his offer, its
                      positioning, its pricing and his sales process.
                    </p>
                  </div>

                  <div class="proof_img">
                    <img
                      src="../assets/images/bannerimages/proof5.png"
                      alt=""
                      class="image-98"
                    />
                  </div>

                  <div class="proof_badges">
                    Helping lead generation agencies scale
                  </div>
                </div>

                <div class="proof_item">
                  <div>
                    <h3>Loes | Saas Growth Agency Owner</h3>
                    <p>
                      Started working with us to help her scale her SaaS agency
                      and implement our client acquisition infrastructure - was
                      having a hard time filling up her calendar.

                      <br /><br />
                      We re-designed the offer to a B&R + implemented our growth
                      protocol
                    </p>
                  </div>

                  <div class="proof_img proofgrid">
                    <div class="col-span-2">
                      <img
                        src="../assets/images/bannerimages/proof6.png"
                        alt=""
                        class="image-98"
                      />
                    </div>
                    <div>
                      <img
                        src="../assets/images/bannerimages/proof7.jpg"
                        alt=""
                        class="image-98"
                      />
                    </div>
                  </div>

                  <div class="proof_badges">Helping SaaS companies grow</div>
                </div>

                <div class="proof_item">
                  <div>
                    <h3>Gurveer & Adam | Founders of Noväk Partners</h3>
                    <p>
                      From struggling to charge $700 usd to selling
                      <span class="text-span-79">$11,500 B&R Offer</span>.
                      Turned the same expertise they were delivering DFY for
                      less than $1k/month into an infrastructure that
                      restaurants are happy to pay almost $12,000 for.
                    </p>
                  </div>

                  <div class="proof_img">
                    <div>
                      <img
                        src="../assets/images/bannerimages/proof8.png"
                        alt=""
                        class="image-98"
                      />
                    </div>
                  </div>

                  <div class="proof_badges">Growth Agency for restaurants</div>
                </div>

                <div class="proof_item">
                  <div>
                    <h3>Miro | Content Agency Owner</h3>
                    <p>
                      Was capped at 5 clients per month & felt the constraint of
                      DFY content creation model for clients. Decided to try
                      selling content infrastructures instead.

                      <br /><br />
                      The outcome?
                      <span class="text-span-79"
                        >$13,000 upfront + $2k/m management fee</span
                      >. Once he saw the potential of the model, decided to
                      become a partner!
                    </p>
                  </div>

                  <div class="proof_img proofgrid2">
                    <div>
                      <img
                        src="../assets/images/bannerimages/proof9.jpg"
                        alt=""
                        class="image-98"
                      />
                    </div>
                    <div>
                      <img
                        src="../assets/images/bannerimages/proof9.png"
                        alt=""
                        class="image-98"
                      />
                    </div>
                  </div>

                  <div class="proof_badges">Content Agency</div>
                </div>

                <div class="proof_item">
                  <div>
                    <h3>Moe Hayek | 8 figure Brand Builder</h3>
                    <p>
                      Not A Partner or Client, yet. Learned about the model from
                      a partner, wasn’t interested at first & then decided to
                      try a B&R Offer, a week later this is the messages I
                      received:
                    </p>
                  </div>

                  <div class="proof_img proofgrid">
                    <div class="col-span-2">
                      <img
                        src="../assets/images/bannerimages/proof10.png"
                        alt=""
                        class="image-98"
                      />
                      <img
                        src="../assets/images/bannerimages/proof12.jpg"
                        alt=""
                        class="image-98"
                      />
                    </div>
                    <div>
                      <img
                        src="../assets/images/bannerimages/proof11.jpg"
                        alt=""
                        class="image-98"
                      />
                    </div>
                  </div>

                  <div class="proof_badges">Growing eCommerce Brands</div>
                </div>
              </div>

              <a
                href="https://www.fanbasis.com/agency-checkout/Clientacquisition.io/kRGY"
                target="_blank"
                ><h4>Join The Natural Born Leaders Community</h4>
                <p>And Access The 7-Figure Sales Agency Walkthrough</p></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="tier_sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="tier_content">
              <img
                src="../assets/images/bannerimages/logo1.png"
                alt=""
                class="banner_logoimg"
              />

              <div class="tier_data">
                <div class="tier_badges">NBL Monthly</div>

                <div class="tier_price">
                  <div class="tier_icon"></div>
                  <div class="tier_ammount">$49/Week</div>
                </div>
                <div class="tier_title">
                  <h1>
                    Natural Born Leaders
                    <span class="text-span-116">(Monthly)</span>
                  </h1>
                  <p class="text-block-272">
                    Get Instant Access To The Natural Born Leaders Club + All
                    The Bonuses Below
                  </p>
                </div>
                <div class="tier_perks tiergrid">
                  <div class="tier_perks_data">
                    <div class="bullet-point"></div>
                    <div class="text-block-273">
                      Access To The 4-Week Growth Creator Challenge.
                    </div>
                  </div>
                  <div class="tier_perks_data">
                    <div class="bullet-point"></div>
                    <div class="text-block-273">
                      Access working Build & Release Infrastructures and offers
                      for 50+ niches.
                    </div>
                  </div>
                  <div class="tier_perks_data">
                    <div class="bullet-point"></div>
                    <div class="text-block-273">
                      20+ hour long growth creator course.
                    </div>
                  </div>
                  <div class="tier_perks_data">
                    <div class="bullet-point"></div>
                    <div class="text-block-273">
                      Access a community of sharks.
                    </div>
                  </div>
                  <div class="tier_perks_data">
                    <div class="bullet-point"></div>
                    <div class="text-block-273">
                      Access to webinar recordings & assets.
                    </div>
                  </div>
                  <div class="tier_perks_data">
                    <div class="bullet-point"></div>
                    <div class="text-block-273">
                      Live group calls & 1on1 calls w/ Serge.
                    </div>
                  </div>
                </div>
                <a
                  href="https://www.fanbasis.com/agency-checkout/Clientacquisition.io/kRGY"
                  target="_blank"
                  ><h4>Yes! I Want To Join The NBL Community!</h4>
                  <p>And Access The 7-Figure Sales Agency Walkthrough</p></a
                >
                <h6>
                  Join NBL for only $49/week - Cancel anytime. No Commitment!
                </h6>

                <img
                  src="../assets/images/bannerimages/tierimgcontent.png"
                  class="image-123"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="cta_sec">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="cta_content">
              <div class="cta_badges">IT'S HAPPENING...</div>

              <h1>
                Join The NBL Community And Access Our Scaling Playbook We
                Implement For Growth Firms In 50+ Niches
              </h1>
              <a
                href="https://www.fanbasis.com/agency-checkout/Clientacquisition.io/kRGY"
                target="_blank"
                ><h4>Join The NBL Community For Just $49/Week</h4>
                <p>And Access The 7-Figure Sales Agency Walkthrough</p></a
              >
              <h6>
                Benefit From Joining The Natural Born Leaders Community For Only
                $49/week. Cancel Anytime.
              </h6>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
.main_sec {
  background-image: radial-gradient(circle, #8fffc0 60%, #75c999);
  padding: 0px;
}

.main_content h2 {
  color: #0c351c;
  font-size: 1.2rem;
  font-weight: 700;
  text-align: center;
  max-width: 900px;
  margin: 10px auto;
  font-family: Faro;
}
.banner_sec {
  background-image: radial-gradient(circle at 50% 0, #0000 22%, #0c0c0c 34%),
    radial-gradient(
      circle farthest-side at 50% -50%,
      #66e29d45 28%,
      #3c95637d 41%,
      #070707 54%
    ),
    url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/66993b7a86ccfbcf3983616a_ezgif-2-4bffdf1e49.webp);
  background-position: 0 0, 0 0, 50% 0;
  background-repeat: repeat, repeat, repeat-x;
  background-size: auto, auto, 26rem;
  padding: 40px 0px;
}
.banner_content {
  max-width: 950px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.banner_content .banner_logoimg {
  width: 13.5rem;
}
.banner_content h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 3.2rem;
  font-weight: 600;
  background-image: linear-gradient(90deg, #e8f5f080, #fff 14% 89%, #e8f5f080);
  color: transparent;
  background-clip: text;
  line-height: 4rem;
}
.banner_content h2 {
  color: #f3faf5;
  font-size: 1.5rem;
  line-height: 2rem;
}

.banner_content a {
  background-image: radial-gradient(
    circle at 50% -100%,
    #40c08c 24%,
    #89ffd0 70%
  );
  border: 2px solid #74ca8b;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 10px 40px;
  box-shadow: inset 0 2px 20px #196138b3, 0 0 40px #5dd39099;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: auto;
}
.banner_content h4 {
  color: #174c27;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Faro;
  margin-bottom: 0;
}

.banner_content p {
  color: #1e9766;
  letter-spacing: 0;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: Faro;
  line-height: 1.5;
  margin-bottom: 0;
}
.banner_content h6 {
  max-width: 400px;
  font-size: 1rem;
  font-style: italic;
  margin: auto;
  color: #f9fdfa;
  font-weight: 300;
  line-height: 1.25;
  font-family: Faro;
}

.commoditi_sec {
  background-image: linear-gradient(
    #080808 0.5%,
    #305f45fa 1.25%,
    #89bea0fa 2%,
    #fcfffd 2.75%
  );
  padding-top: 8rem;
  padding-bottom: 4rem;
}

.commoditi_content {
  max-width: 950px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
  font-family: Faro;
}

.commoditi_content h1 {
  background-image: linear-gradient(
    90deg,
    #406d5c80,
    #0d251a 29% 76%,
    #406d5c80
  );
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 3.2rem;
  font-weight: 600;
  color: transparent;
  line-height: 4rem;
  -webkit-background-clip: text;
}

.commoditi_content p {
  color: #0d251a;
  margin-bottom: 2rem;
  font-family: "Faro 11", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.6;
  font-family: Faro;
  text-align: left;
  max-width: 800px;
  margin: auto;
}

.commoditi_content p strong {
  border-bottom: 0.0625rem #000;
  font-weight: 700;
  text-decoration: none;
  font-family: Faro;
}
.commoditi_content p span.text-span-129 {
  font-weight: 700;
}

.commoditi_content a {
  background-image: radial-gradient(
    circle at 50% -100%,
    #071610 24%,
    #16573d 64%
  );
  box-shadow: inset 0 2px 20px #5ece8fb3, 0 0 40px #5dd39099;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 10px 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: auto;
}

.commoditi_content h4 {
  color: #f3fdf6;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Faro;
  margin-bottom: 0;
}

.commoditi_content a p {
  color: #a5d5c2;
  letter-spacing: 0;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: Faro;
  line-height: 1.5;
  margin-bottom: 0;
}

.commoditi_content .secondheading {
  background-image: linear-gradient(
    90deg,
    #406d5c80,
    #0d251a 29% 76%,
    #406d5c80
  );
  max-width: 900px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: "Faro 11", sans-serif;
  font-size: 3rem;
  font-weight: 700;
}

.commoditi_content .tradition_img {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 900px;
  margin: auto;
  gap: 40px 20px;
}
.commoditi_content .tradition_img img {
  width: 420px;
}
.commoditi_content .tradition_img .home3 {
  grid-column: 1/3;
  width: 100%;
}

.commoditi_content p .text-span-87 {
  font-weight: 700;
  text-decoration: underline;
  font-family: Faro;
}

.commoditi_content p .text-span-88 {
  background-color: #b8fddd;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 700;
  text-decoration: none;
  font-family: Faro;
}
.commoditi_content .reportoverview {
  max-width: 900px;
  margin: auto;
}
.commoditi_content .reportoverview .home4 {
  width: 700px;
}
.text-span-112,
.text-span-113,
.text-span-114 {
  font-weight: 700;
  text-decoration: underline;
}
.commoditi_content .reportoverview .home5 {
  width: 100%;
}
.commoditi_content .subheadline {
  background-image: linear-gradient(
    90deg,
    #406d5c80,
    #0d251a 29% 76%,
    #406d5c80
  );
  max-width: 900px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: Faro;
  font-size: 3rem;
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0px;
}

.commoditi_content .nblcommunity {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.commoditi_content .nblcommunity img {
  width: 240px;
}

.whonbl_sec {
  background-image: radial-gradient(
      circle at 200%,
      #3c9563f5 30%,
      #0b0b0b00 54%
    ),
    radial-gradient(circle at -100%, #66e29df5 29%, #3c9563f5 35%, #0b0b0b 54%),
    url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/662aeb1a45340c70fc437274_VideoBackground.jpg);
  background-position: 0 0, 0 0, 0 0;
  background-repeat: repeat, repeat, repeat;
  background-size: auto, auto, 26rem;
}
.whonbl_content {
  max-width: 950px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
.whonbl_content h1 {
  color: white;
  background-image: none;
  background-clip: border-box;
  max-width: 1000px;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}

.whonbl_content h1 .text-span-122 {
  color: #71f1ba;
}

.whonbl_content h1.subheadline {
  background-image: none;
  background-clip: border-box;
  max-width: 800px;
  margin: auto;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-family: Faro;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.5;
}

.whonbl_content h1.subheadline strong {
  border-bottom: 0.0625rem #000;
  font-weight: 700;
  text-decoration: none;
}

.whonbl_content .nbl_perks {
  background-color: #0a0a0a;
  background-image: url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/66970f41756bba070c4f1d63_Rectangle%2013%20\(
        10\
      ).png),
    linear-gradient(196deg, #0e3a1f, #060f0b 15%);
  background-position: 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 100% 100%, auto;
  border-radius: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 900px;
  min-height: 22rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  position: relative;
  box-shadow: 0 0 60px #2e574180;
}
.whonbl_content .nbl_perks h1 {
  font-family: Faro;
  font-size: 2.4rem;
  font-weight: 500;
  text-align: left;
}
.whonbl_content .nbl_perks h1 .text-span-117 {
  color: #6cdf9a;
  font-size: 1.5rem;
}
.whonbl_content .nbl_perks p {
  margin-top: 20px;
  font-family: Faro;
  font-weight: 500;
  font-size: 1.2rem;
  color: white;
}
.whonbl_content .nbl_perks .perkimg {
  width: 370px;
  position: relative;
  left: 30%;
}
.feature_sec {
  background-image: radial-gradient(
      circle farthest-side at 200%,
      #66e29df5 32%,
      #3c9563f5 37%,
      #070707 56%
    ),
    url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/662aeb1a45340c70fc437274_VideoBackground.jpg);
  background-position: 0 0, 0 0;
  background-size: auto, 26rem;
  border-top: 1px #4a6d59;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  overflow: unset;
}
.feature_content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: sticky;
  top: 20px;
}
.feature_content h1 {
  color: white;
  background-image: none;
  background-clip: border-box;
  font-weight: 500;
  text-align: left;
  font-size: 2.7rem;
  -webkit-background-clip: text;
}
.feature_content h1.subheadline {
  color: #e8f1ec;
  text-align: left;
  -webkit-text-fill-color: inherit;
  background-image: none;
  background-clip: border-box;
  max-width: none;
  margin-top: 0;
  font-family: Whyte Book, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.5;
}
.feature_content a {
  background-image: radial-gradient(
    circle at 50% -100%,
    #40c08c 24%,
    #89ffd0 70%
  );
  border: 2px solid #74ca8b;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 10px 40px;
  box-shadow: inset 0 2px 20px #196138b3, 0 0 40px #5dd39099;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.feature_content h4 {
  color: #174c27;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Faro;
  margin-bottom: 0;
}
.feature_content p {
  color: #1e9766;
  letter-spacing: 0;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: Faro;
  line-height: 1.5;
  margin-bottom: 0;
}
.feature_data .feature_item {
  background-image: linear-gradient(350deg, #152218 81%, #2b5538);
  margin-bottom: 1rem;
  border: 1px solid #31493c;
  border-radius: 4px 8px 8px 4px;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 10rem;
  padding: 20px;
  position: relative;
  box-shadow: inset 0 0 30px #0b1f1280, 0 0 20px -10px #5aa87c99;
}
.feature_data .feature_item .leftborder {
  background-color: #9bcfb3;
  border-radius: 1rem;
  width: 4px;
  max-width: 4px;
  height: 100%;
  min-height: 10rem;
  margin-right: 20px;
  position: absolute;
  left: 0;
  top: 0;
  box-shadow: 0 0 20px #a6ecc5cc;
}
.checkbox.input {
  background-image: url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/64985e87a6201fd47b7f65a9_tick-square.svg);
  min-width: 1.5rem;
  margin-right: 10px;
}
.checkbox {
  background-image: url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/6499e0c341d4f4f0b85c729a_check.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: auto;
  width: 1.5rem;
  height: 1.5rem;
}
.feature_data .feature_item p {
  color: #f0faf4;
  font-family: Whyte Book, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 0;
}
.excess_sec {
  background-color: #080808;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: auto;
  padding-top: 6rem;
  padding-bottom: 4rem;
  display: flex;
  position: relative;
}
.excess_content {
  width: 100%;
  max-width: 950px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.excess_content h1 {
  color: white;
  background-image: none;
  background-clip: border-box;
  max-width: 1000px;
  margin-bottom: 1rem;
  font-family: Neuemontreal, sans-serif;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;
}
.excess_item {
  z-index: 2;
  background-image: linear-gradient(174deg, #174632, #0b1b14 24%, #18583a);
  border: 1px solid #33553c;
  border-left: 2px solid #60d37f;
  border-radius: 5px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding: 25px 2rem;
  position: relative;
  box-shadow: inset 0 0 20px #53fc9166;
}
.excess_first {
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  display: flex;
}
.excess_first .glowing-circle {
  min-width: 10px;
  height: 10px;
  background-color: #73eebc;
  border-radius: 50%;
  position: relative;
  animation: glow 4s infinite;
  opacity: 1;
  animation: fade-text 4s infinite;
}
.excess_first h1 {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 1.4rem;
  font-weight: 500;
  display: inline-block;
}
.excess_first .glowing-text {
  font-size: 0.9rem;
  font-weight: bold;
  color: #73eebc;
  animation: fade-text 4s infinite;
  opacity: 1;
  text-transform: uppercase;
}
.excess_second {
  color: #91aca0;
  font-family: Faro;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5;
  text-align: left;
}
.excess_item2 {
  z-index: 1;
  transform: translateY(-25px);
  max-width: 650px;
  filter: blur(5px);
}
.excess_item3 {
  z-index: 0;
  transform: translateY(-50px);
  max-width: 600px;
  filter: blur(5px);
}
@keyframes fade-text {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.case_sec {
  background-image: radial-gradient(
      circle closest-corner at 50% -5%,
      #50c784f5 46%,
      #2e7c50f5 56%,
      #090909 92%
    ),
    url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/662aeb1a45340c70fc437274_VideoBackground.jpg);
  background-position: 0 0, 0 0;
  background-size: auto, 22rem;
  border-top: 1px solid #1e3829;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  position: relative;
}
.case_content {
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}
.case_content .case_badges {
  color: #1e9766;
  background-color: #f3fff5;
  border: 1px solid #abdfbe;
  border-radius: 10rem;
  padding: 2px 20px;
  font-family: "Faro 11", sans-serif;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.1rem;
  display: inline-block;
  box-shadow: 0 0 20px #a8ffce99, inset 0 0 20px #79e0b6;
  max-width: fit-content;
  text-align: center;
}
.case_content h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  background-image: linear-gradient(90deg, #e8f5f080, #fff 14% 89%, #e8f5f080);
  color: transparent;
  background-clip: text;
  line-height: 4rem;
  font-family: Neuemontreal, sans-serif;
  font-size: 3.5rem;
  font-weight: 700;
  text-align: center;
}
.proofcopy_data {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.proofcopy_data .proof_item {
  z-index: 1;
  background-image: linear-gradient(58deg, #191919 80%, #1f3529);
  border: 1px solid #3a634c;
  border-top-color: #618d75;
  border-radius: 1.5rem;
  flex-direction: column;
  width: 100%;
  max-width: none;
  height: auto;
  min-height: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem;
  display: flex;
  gap: 20px;
  position: relative;
  box-shadow: 0 0 40px #3c956399;
}
.proofcopy_data .proof_item h3 {
  margin: 0 0;
  font-family: Faro;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.5;
  color: white;
}
.proofcopy_data .proof_item p {
  color: #fdfffe;
  font-family: Faro;
  font-size: 1.3rem;
}
.proofcopy_data .proof_item p span {
  color: #092c19;
  background-color: #91ffd3;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  font-family: Whyte Book, sans-serif;
  font-weight: 700;
  display: inline-block;
}
.proofcopy_data .proof_item .proof_img img {
  border: 1px solid #4a5e52;
  border-radius: 1rem;
  min-width: 100%;
  max-width: 100%;
  margin-top: 1rem;
}
.proofcopy_data .proof_item .proof_badges {
  color: #c2cfc8;
  border: 1px solid #2a3630;
  border-radius: 10rem;
  margin-top: 0;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.2rem;
  line-height: 1.8;
  display: inline-block;
  max-width: fit-content;
  font-family: Faro;
}
.proofgrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.proofgrid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.col-span-2 {
  grid-column: 1/3;
}
.case_content a {
  background-image: radial-gradient(
    circle at 50% -100%,
    #40c08c 24%,
    #89ffd0 70%
  );
  border: 2px solid #74ca8b;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 10px 40px;
  box-shadow: inset 0 2px 20px #196138b3, 0 0 40px #5dd39099;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: auto;
}
.case_content h4 {
  color: #174c27;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Faro;
  margin-bottom: 0;
}
.case_content p {
  color: #1e9766;
  letter-spacing: 0;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: Faro;
  line-height: 1.5;
  margin-bottom: 0;
}
.tier_sec {
  background-image: radial-gradient(
      circle farthest-side at 50% -50%,
      #66e29df5 35%,
      #3c9563f5 38%,
      #070707 45%
    ),
    url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/662aeb1a45340c70fc437274_VideoBackground.jpg);
  background-position: 0 0, 0 0;
  background-size: auto, 26rem;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: auto;
  padding-top: 2rem;
  padding-bottom: 4rem;
  display: flex;
  position: relative;
}
.tier_content {
  z-index: 1;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}
.tier_content img {
  width: 12rem;
  height: 3rem;
  margin-top: 20px;
}
.tier_data {
  background-color: #0a0a0a;
  background-image: url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/66966b180c117f03e0ad8cac_Rectangle%2013%20\(
        7\
      ).png),
    linear-gradient(196deg, #0e3a1f, #08140e 15%);
  background-position: 50%, 0 0;
  background-repeat: no-repeat, repeat;
  background-size: 100% 100%, auto;
  border-radius: 1.25rem;
  width: 100%;
  max-width: 800px;
  min-height: auto;
  margin-top: 5rem;
  margin-bottom: 2rem;
  padding: 2rem;
  position: relative;
  box-shadow: 0 0 60px #3b7c5980;
}
.tier_badges {
  position: absolute;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  display: flex;
  flex-flow: column;
  position: absolute;
  inset: -2% 0% auto;
  color: #245331;
  -webkit-text-fill-color: inherit;
  background-color: #daf8e2;
  background-clip: border-box;
  border-radius: 10rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-family: "Faro 11", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.5;
  max-width: fit-content;
  box-shadow: 0 0 30px #90f1bd80, inset 0 0 10px #3ab97ecc;
}
.tier_data {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.tier_price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tier_icon {
  background-image: url("../assets/images/bannerimages/tierimg.png");
  background-position: 50%;
  background-size: cover;
  border: 1px solid #3b684a;
  border-radius: 10px;
  width: 4rem;
  height: 4rem;
}
.tier_ammount {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#f5fffb 50%, #a7e0c3);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Faro;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
}
.tier_title h1 {
  font-size: 2rem;

  font-weight: 500;
  color: white;
}
.tier_title h1 .text-span-116 {
  color: #f8f8f8;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 500;
}
.tier_title p {
  margin-top: 10px;
  font-size: 1.1rem;
  font-family: Faro;
  font-weight: 400;
  color: white;
}
.tier_perks {
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.tiergrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 10px;
}
.tier_perks_data {
  display: flex;
  gap: 10px;
}
.bullet-point {
  background-image: url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/669667837bbc09248e08676c_Rectangle%2015.png);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 90%;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  margin-right: 10px;
}
.text-block-273 {
  margin-top: 0;
  font-family: Faro;
  font-weight: 500;
  color: white;
  font-size: 1.2rem;
  line-height: 1.5;
}

.tier_content a {
  background-image: radial-gradient(
    circle at 50% -100%,
    #40c08c 24%,
    #89ffd0 70%
  );
  border: 2px solid #74ca8b;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 10px 40px;
  box-shadow: inset 0 2px 20px #196138b3, 0 0 40px #5dd39099;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: auto;
}
.tier_content h4 {
  color: #174c27;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Faro;
  margin-bottom: 0;
}
.tier_content p {
  color: #1e9766;
  letter-spacing: 0;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: Faro;
  line-height: 1.5;
  margin-bottom: 0;
}

.tier_content h6 {
  max-width: 500px;
  font-size: 1rem;
  font-style: italic;
  margin: auto;
  color: #f9fdfa;
  font-weight: 300;
  line-height: 1.25;
  font-family: Faro;
}

.tier_data img {
  width: 320px;
  height: auto;
  margin-top: 20px;
  max-width: 500px;
  margin: auto;
}

.cta_sec {
  background-image: radial-gradient(
      circle farthest-side at 50% 180%,
      #66e29df5 46%,
      #3c9563f5 49%,
      #0a0a0a 57%
    ),
    url(https://cdn.prod.website-files.com/644c9559392a3d48a1d816c7/662aeb1a45340c70fc437274_VideoBackground.jpg);
  background-position: 0 0, 0 0;
  background-size: auto, 26rem;
  border-top: 1px solid #4a6d59;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  height: auto;
  min-height: 100vh;
  padding-top: 4rem;
  padding-bottom: 1rem;
  display: flex;
  position: relative;
}

.cta_content {
  max-width: 900px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}
.cta_content .cta_badges {
  color: #24804f;
  -webkit-text-fill-color: inherit;
  background-color: #d7ffe5;
  background-image: none;
  background-clip: border-box;
  border-radius: 10rem;
  padding: 5px 20px;
  font-family: Faro;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.2;
  max-width: fit-content;
  box-shadow: 0 0 20px #abffcd80, inset 0 0 10px #35a06780;
}

.cta_content h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-weight: 500;
  color: white;
  line-height: 4rem;
  text-align: center;
  max-width: 1000px;
}

.cta_content a {
  background-image: radial-gradient(
    circle at 50% -100%,
    #40c08c 24%,
    #89ffd0 70%
  );
  border: 2px solid #74ca8b;
  margin-top: 1rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 10px 40px;
  box-shadow: inset 0 2px 20px #196138b3, 0 0 40px #5dd39099;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: auto;
}
.cta_content h4 {
  color: #174c27;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: Faro;
  margin-bottom: 0;
}

.cta_content p {
  color: #1e9766;
  letter-spacing: 0;
  font-size: 1.1rem;
  font-weight: 600;
  font-family: Faro;
  line-height: 1.5;
  margin-bottom: 0;
}
.cta_content h6 {
  text-align: center;
  max-width: 500px;
  font-size: 1rem;
  margin: auto;
  color: #f9fdfa;
  font-weight: 300;
  line-height: 1.3;
  font-family: Faro;
}


@media screen and (min-width: 992px) and (max-width: 1199px) {
  .commoditi_content .tradition_img img {
    width: 340px;
  }
  .commoditi_content .tradition_img .home3 {
    grid-column: 1/3;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .banner_content h1,
  .commoditi_content h1 {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  .commoditi_content .secondheading {
    font-size: 2.2rem;
  }
  .commoditi_content .tradition_img {
    grid-template-columns: repeat(1, 1fr);
  }
  .commoditi_content .tradition_img img {
    width: 100%;
    grid-column: 1/3;
  }
  .commoditi_content .nblcommunity[data-v-9ea40744] {
    grid-template-columns: repeat(2, 1fr);
  }

  .whonbl_content h1 {
    font-size: 2.5rem;
  }
  .whonbl_content .nbl_perks {
    flex-direction: column;
  }
  .whonbl_content .nbl_perks .perkimg[data-v-9ea40744] {
    left: 0%;
  }
  .feature_row {
    flex-direction: column;
  }

  .excess_content h1 {
    font-size: 2.5rem;
  }
  .excess_first h1 {
    font-size: 1.4rem;
  }
  .case_content h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .proofcopy_data .proof_item h3 {
    font-size: 1.8rem;
  }
  .proofcopy_data .proof_item p {
    font-size: 1.2rem;
  }
  .proofcopy_data .proof_item .proof_badges {
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: center;
  }
  .proofgrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .proofgrid2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cta_content h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .banner_content h1,
  .commoditi_content h1 {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  .commoditi_content .secondheading {
    font-size: 2.2rem;
  }
  .commoditi_content .tradition_img {
    grid-template-columns: repeat(1, 1fr);
  }
  .commoditi_content .tradition_img img {
    width: 100%;
    grid-column: 1/3;
  }
  .commoditi_content .reportoverview .home4 {
    width: 100%;
    height: auto;
  }

  .commoditi_content .nblcommunity[data-v-9ea40744] {
    grid-template-columns: repeat(2, 1fr);
  }
  .whonbl_content h1 {
    font-size: 2.5rem;
  }
  .whonbl_content .nbl_perks {
    flex-direction: column;
  }
  .whonbl_content .nbl_perks .perkimg[data-v-9ea40744] {
    left: 0%;
  }
  .feature_row {
    flex-direction: column;
  }

  .excess_content h1 {
    font-size: 2rem;
  }
  .excess_first h1 {
    font-size: 1.4rem;
  }
  .case_content h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .proofcopy_data .proof_item h3 {
    font-size: 1.8rem;
  }
  .proofcopy_data .proof_item p {
    font-size: 1.2rem;
  }
  .proofcopy_data .proof_item .proof_badges {
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: center;
  }
  .proofgrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .proofgrid2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .cta_content h1 {
    font-size: 2rem;
    line-height: 3rem;
  }
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .banner_content h1,
  .commoditi_content h1 {
    font-size: 2.2rem;
    line-height: 3rem;
  }
  .commoditi_content .secondheading {
    font-size: 2.2rem;
  }
  .commoditi_content .tradition_img {
    grid-template-columns: repeat(1, 1fr);
  }
  .commoditi_content .tradition_img img {
    width: 100%;
    grid-column: 1/3;
  }
  .commoditi_content .reportoverview .home4 {
    width: 100%;
    height: auto;
  }
  .commoditi_content .nblcommunity {
    grid-template-columns: repeat(1, 1fr);
  }
  .whonbl_content h1 {
    font-size: 2rem;
  }
  .whonbl_content .nbl_perks {
    flex-direction: column;
  }

  .whonbl_content .nbl_perks .perkimg {
    left: 0%;
    width: 100%;
  }
  .whonbl_content .nbl_perks h1 {
    font-size: 1.8rem;
  }
  .feature_row {
    flex-direction: column;
  }
  .feature_content h1 {
    font-size: 2rem;
  }
  .feature_data .feature_item p {
    font-size: 1.2rem;
  }
  .excess_content h1 {
    font-size: 2rem;
  }
  .excess_first h1 {
    font-size: 1rem;
  }
  .excess_second {
    font-size: 1.1rem;
  }
  .excess_first {
    align-items: center;
  }
  .case_content h1 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .proofcopy_data .proof_item h3 {
    font-size: 1.8rem;
  }
  .proofcopy_data .proof_item p {
    font-size: 1.2rem;
  }
  .proofcopy_data .proof_item .proof_badges {
    font-size: 1.1rem;
    line-height: 1.5;
    text-align: center;
  }
  .proofgrid {
    grid-template-columns: repeat(1, 1fr);
  }
  .proofgrid2 {
    grid-template-columns: repeat(1, 1fr);
  }

  .tier_price {
    flex-direction: column;
    gap: 25px;
  }
  .tier_ammount {
    font-size: 1.5rem;
  }
  .tiergrid {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px 10px;
  }
  .tier_data {
    padding: 1.5rem;
  }
  .tier_title h1 {
    font-size: 1.5rem;
  }
  .tier_data img {
    width: 100%;
  }
  .cta_content h1 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  a {
    justify-content: center;
  }
  .commoditi_content a p {
    text-align: center;
  }
  .feature_content a,
  .case_content a,
  .cta_content a,
  .tier_content a {
    text-align: center;
  }
  .tier_content h6 {
    text-align: center;
  }
}
</style>
