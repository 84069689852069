<template>
  <div>
    <button class="primary-btn">
        {{ btntext }}
    </button>
    </div>
</template>

<script>
export default {
name:'PrimaryButton',
props:{
    btntext:String
}
}
</script>

<style scoped>
.primary-btn {
    background:var(--cool-blue);
    color:var(--cool-darkblue);
    padding:var(--fs-10) var(--fs-25);
    font-weight: 700;
    font-size:var(--fs-25);
    border-radius: var(--fs-50);
    outline:none;
    text-transform: capitalize;
    border:var(--fs-2) solid var(--cool-blue);
    text-align: center;
    transition: all 0.2s ease;
}

.primary-btn:hover{
    background:var(--cool-darkblue);
    color:var(--cool-blue);
    padding:var(--fs-10) var(--fs-25);
    font-weight: 700;
    font-size:var(--fs-25);
    border-radius: var(--fs-50);
    outline:none;
    text-transform: capitalize;
    border:var(--fs-2) solid var(--cool-blue);
    text-align: center;
}


@media screen and (min-width:320px) and (max-width:575px) {
    .primary-btn {
        font-size:var(--fs-20)
    }
    .primary-btn:hover {
        font-size:var(--fs-20)
    }
}
</style>