<template>
  <div>
    <footer>
      <div class="container">
             <div class="row ">
                <div class="col-12">
                     <div class="d-flex justify-content-lg-between justify-content-center  align-items-center flex-wrap gap-lg-2 gap-4 ">
                       <div class="logoimg">
                      <router-link to="/" class="outline-none border-none"
                          ><img src="@/assets/images/logo.png" alt="logo"
                        /></router-link>
                        <div class="logoimgbg"></div>
                      </div>
                      <ul class="footerpayment">
                        <li>
                          <router-link to="/">
                            <img src="../assets/images/bannerimages/paypal.png"/>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/">
                            <img src="../assets/images/bannerimages/visa.png"/>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/">
                            <img src="../assets/images/bannerimages/american-express.png"/>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/">
                            <img src="../assets/images/bannerimages/mastercard.png"/>
                            </router-link>
                        </li>
                      
                      </ul>

                      <ul  class="logofooter">
                        <li>
                          <router-link to="/">
                            <BxFacebook class="icon"/>
                            <BxFacebook class="icon1"/>
                            <span></span>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/">
                            <FaBandsXTwitter class="icon"/>
                            <FaBandsXTwitter class="icon1"/>
                            <span></span>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/">
                            <AkInstagramFill class="icon"/>
                            <AkInstagramFill class="icon1"/>
                            <span></span>
                          </router-link>
                        </li>
                        <li>
                          <router-link to="/">
                            <BsYoutube class="icon"/>
                            <BsYoutube class="icon1"/> 
                            <span></span>
                            </router-link>
                        </li>
                      
                      </ul>
                    </div>
                </div>
             </div>   
             <div class="row my-5  justify-content-center">
                <div class=" col-lg-4 col-sm-6 ">
                  <div class="footernavigation">
                    <h4>Navigation</h4>
                    <ul>
                      <li>
                        <router-link to="/">
                          <p>Home</p>
                          <p>Home</p>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/">
                          <p>Payout</p>
                          <p>Payout</p>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/">
                          <p>Product</p>
                          <p>Product</p>
                        </router-link>
                      </li>
                  
                    </ul>
                  </div>
                </div>
                <div class=" col-lg-4 col-sm-6">
                  <div class="footernavigation">
                    <h4>Support</h4>
                    <ul>
                      <li>
                        <router-link to="/">
                          <p>Faq</p>
                          <p>Faq</p>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/">
                          <p>Contact</p>
                          <p>Contact</p>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/">
                          <p>Be a Partner</p>
                          <p>Be a Partner</p>
                        </router-link>
                      </li>
                  
                    </ul>
                  </div>
                </div>
                <div class=" col-lg-4 col-sm-12">
                  <div class="footernavigation">
                    <h4>Legal Policy</h4>
                    <ul>
                      <li>
                        <router-link to="/">
                          <p>Privacy Policy</p>
                          <p>Privacy Policy</p>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/">
                          <p>Terms of Services</p>
                          <p>Terms of Services</p>
                        </router-link>
                      </li>
                      <li>
                        <router-link to="/">
                          <p>Cookie Policy</p>
                          <p>Cookie Policy</p>
                        </router-link>
                      </li>

                      <li>
                        <router-link to="/">
                          <p>Refund and Cancellation Policy</p>
                          <p>Refund and Cancellation Policy</p>
                        </router-link>
                      </li>
                  
                    </ul>
                  </div>
                </div>
            </div>
           
            <div class="row ">
              <div class="col-12">
                <div class="companyinfo  ">
                  <p>
                    All content published and distributed by Global Funded WEALTH GROUP LTD and its affiliates is intended to be treated as general information only. None of the information provided by the company or contained here is intended as investment advice, an offer or solicitation of an offer to buy or sell, or as a recommendation, endorsement, or sponsorship of any security, company, or fund. Testimonials may not be representative of other clients or customers and are not a guarantee of future performance or success. GlobalFunded is not a broker and does not accept client deposits. All program fees are used for operational costs, including staff, technology, and other business-related expenses. GlobalFunded itself does not carry out any regulated activities; our exclusive activities are Prop Trading and Trading Education and we are therefore not required to be authorized by regulatory authorities.
                  </p>

                  <p class="copyright">&copy; Global Funded 2024</p>
                </div>
                </div>
            </div>
          </div>

           
          
  </footer>
  </div>

</template>
<script>
import ApiClass from '@/api/api.js';
import { BxFacebook ,FaBandsXTwitter ,AkInstagramFill , BsYoutube} from '@kalimahapps/vue-icons';

export default {
  name: "FooterComponent",
  data(){
    return {
      pdfurl : ApiClass.pdfUrl
    }
  },
  methods:{

    download(url) { 
      url = this.pdfurl + url;
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank';
      a.download = url.split('/').pop() 
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  } ,
  components:{
    BxFacebook,
    FaBandsXTwitter,
    AkInstagramFill,
    BsYoutube
  }
};
</script>
<style scoped>

footer {
  background:var(--lineargradient1);
  border-top-left-radius:200px;
  border-top-right-radius:200px;
  padding:100px 0px;
}

footer .logoimg {
  position:relative;
  z-index:5;
}

footer .logoimgbg{
  position: absolute;
  left:50%;
  top:50%;
  width:400px;
  height:400px;
  transform:translate(-50%,-50%);
  background:var(--cool-darkblue);
  filter:blur(150px);
  border-radius:50%;
  z-index:-1;
}
.footerpayment  {
  margin:0;
  padding:0;
  display: flex;
  align-items:center;
  gap:30px;

}
.footerpayment li a img {
  width:80px;
  mix-blend-mode: multiply;

}
.logofooter {
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.logofooter  {
  display: flex;
  margin:0;
  padding:0;
  gap:20px;
}

.logofooter li a {
    min-width:50px;
    height:50px;
    border-radius:50%;
    background:var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index:0;
    transition:all 0.5s;
}

.logofooter li a span {
  position: relative;
  background:var(--white);
  min-width:100%;
  height:100%;
  border-radius: 50%;
  transform:scale(0.2);
  z-index:1;
  transition:all 0.5s;
  display:inline-block;
}

.logofooter li a .icon {
  position: absolute;
  color:var(--white);
  transform: translateX(-200%);
  z-index:5;
  transition:all 0.5s;
  font-size:25px;
}
.logofooter li a .icon1 {
  color:var(--cool-darkblue);
  position: absolute;
  transition:all 0.5s;
  z-index:2;
  font-size:25px;
}
.logofooter a:hover {
  background:transparent;
}
.logofooter a:hover span{
  background:var(--cool-darkblue);
  transform:scale(1);
}

.logofooter a:hover .icon {
  transform: translateX(0);
  z-index:3;
}
.logofooter a:hover .icon1{
  transform: translateX(200%);
}




.footernavigation{
  text-align: center;
  margin-bottom: 20px;
}
.footernavigation h4{
  font-size:var(--fs-25);
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
}


.footernavigation ul {
  margin:0;
  padding:0;
}

.footernavigation ul li a {
  font-size:var(--fs-22);
  font-weight: 600;
  color:var(--white);
  padding:10px;
  overflow: hidden;
  width: max-content;
  height:50px;
  transition: all 0.5s;
}
.footernavigation ul li a p:nth-child(odd) {
  transition: all 0.5s;
}
.footernavigation ul li a p:nth-child(even) {
 color:var(--cool-darkblue);
 transition: all 0.5s;
}

.footernavigation ul li a:hover p:nth-child(odd) {
  transform: translateY(-160%);
}

.footernavigation ul li a:hover p:nth-child(even) {
  transform: translateY(-160%);
}


.companyinfo p {
  font-size:var(--fs-20);
  text-align: center;
  font-weight: 600;
  line-height: 32px;
  color:var(--cool-darkblue)
}

.companyinfo .copyright {
  color:var(--white);
  filter:drop-shadow(0 0 10px var(--cool-darkblue));
}



@media screen and (min-width:320px) and (max-width:575px)  {
  footer{
    padding: 60px 0px;
}
  .footerpayment li a img {
    width: 50px;
}

.footernavigation ul li a {
    font-size: var(--fs-20);
    
}

}
</style>