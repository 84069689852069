<template>
  <header>
    <div class="main_header">
      <div class="navimg">
        <router-link to="/" class="outline-none border-none"
          ><img src="@/assets/images/logo.png" alt="logo"
        /></router-link>
      </div>

      <div class="navbar">
        <div class="d-lg-none d-block" id="close">
          <i class="ri-close-large-fill"></i>
        </div>
        <nav>
          <ul>
            <li class="nav_list logoimg d-lg-none d-block menuitem2">
              <router-link to="/" class="nav_link"
                ><img src="../assets/images/logo.png" alt="logo"
              /></router-link>
            </li>
            <li class="nav_list menuitem1">
              <router-link to="/" class="nav_link">Home</router-link>
            </li>
            <li class="nav_list menuitem2">
              <router-link to="/payout" class="nav_link">Payout</router-link>
            </li>
            <li class="nav_list menuitem1">
              <router-link to="/product" class="nav_link">Products</router-link>
            </li>
            <li class="nav_list menuitem2">
              <router-link to="/faq" class="nav_link">FAQs</router-link>
            </li>
            <li class="nav_list menuitem1">
              <router-link to="contact" class="nav_link"
                >Contact Us</router-link
              >
            </li>
            <li class="menuitem2">
              <a href="https://trading-dashboard.com/metrics/" class="nav_link">
                <PrimaryButton btntext="login" />
              </a>
            </li>
          </ul>
        </nav>
      </div>

      <i class="ri-menu-3-fill d-lg-none d-block" id="menu"></i>
    </div>
  </header>
</template>
<script>
import PrimaryButton from "./PrimaryButton.vue";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  name: "HeaderComponent",
  components: {
    PrimaryButton,
  },
  data() {
    return {
      couponCode: "JULY35",
    };
  },
  mounted() {
    let navlinks = document.querySelectorAll(".nav_link");

    navlinks.forEach((navlink) => {
      navlink.addEventListener("click", function (e) {
        navlinks.forEach((link) => link.classList.remove("active"));
        this.classList.add("active");
      });
    });

    this.headerAnimation();
    this.scrollheader();
  },

  methods: {
    copyCouponCode() {
      this.$refs.couponCodeInput.select();

      try {
        // Execute copy command
        document.execCommand("copy");
        // Provide feedback or handle success as needed
        alert("Coupon code copied");
      } catch (err) {
        // Fallback or handle error
        console.error("Unable to copy coupon code:", err);
      }
    },

    scrollheader() {
      window.addEventListener("scroll", function () {
        var header = document.querySelector("header");
        if (scrollY >= 400) {
          header.classList.add("changeheader");
        } else {
          header.classList.remove("changeheader");
        }
      });
    },

    headerAnimation() {
      var menu = document.querySelector("#menu");
      var close = document.querySelector("#close");
      var tl = gsap.timeline();
      if (window.innerWidth <= 991) {
        tl.to(".navbar", {
          right: 0,
          duration: 0.5,
        });

        tl.from(".menuitem1", {
          x: 100,
          opacity: 0,
          duration: 0.5,
          stagger: 0.3,
        });

        tl.from(".menuitem2", {
          x: -100,
          opacity: 0,
          duration: 0.5,
          stagger: 0.3,
        });
        tl.pause();
        menu.addEventListener("click", function () {
          tl.play();
        });

        close.addEventListener("click", function () {
          tl.reverse();
        });
      } else if (window.innerWidth > 991) {
        tl.from(".menuitem1,.menuitem2", {
          y: 50,
          opacity: 0,
          duration: 1,
          stagger: 0.3,
        });
      }
    },
  },
};
</script>

<style scoped>
/* header css start */
header {
  background: "transparent";
  width: 100%;
  padding: 10px 20px;
  z-index: 22;
  position: absolute;
  transition: all 0.5s ease;
}

header.changeheader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1c282f;
  filter: drop-shadow(0 0 10px var(--cool-blue));
}
.main_header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main_header img {
  width: 220px;
}
.main_header i {
  font-size: 30px;
  color: var(--cool-blue);
  cursor: pointer;
}

.navbar nav {
  width: 100%;
  height: 100%;
}

.navbar nav ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  gap: 30px;
}

.navbar nav ul li {
  text-align: center;
}

.navbar #close {
  position: absolute;
  right: 10%;
  top: 2%;
  cursor: pointer;
}
.navbar #close i {
  font-weight: 700;
  font-size: 25px;
  background-color: var(--cool-blue);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cool-darkblue);
}
.navbar nav ul .nav_list .nav_link {
  color: var(--white);
  cursor: pointer;
  position: relative;
  font-size: var(--fs-22);
  font-weight: 600;
  transition: 0.1s;
  white-space: nowrap;
}

.navbar nav ul .nav_list .nav_link.active,
.navbar nav ul .nav_list .nav_link:hover {
  color: var(--cool-blue);
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    position: fixed;
    height: 100vh;
    max-width: 100%;
    width: 280px;
    right: -280px;
    top: 0%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    z-index: 100;
    padding: 90px 0px;
  }
  .navbar nav ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 30px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .navbar {
    position: fixed;
    height: 100vh;
    max-width: 100%;
    width: 280px;
    right: -280px;
    top: 0%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    z-index: 100;
    padding: 90px 0px;
  }
  .navbar nav ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 30px;
  }
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .navbar {
    position: fixed;
    height: 100vh;
    max-width: 100%;
    width: 280px;
    right: -280px;
    top: 0%;
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    z-index: 100;
    padding: 90px 0px;
  }

  .navbar nav ul {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    gap: 30px;
  }
}
</style>
